<template>
  <div>
    <div class="display-inline-flex header-section">
      <!--  v-if="widgetConfig?.showSearch" -->
      <div class="pointer-cursor">
        <SfIcon :icon="'search'" size="xl" @click="showOptions" class="d-flex"/>
      </div>
      <div class="pointer-cursor cart-section" v-if="(widgetConfig?.showCart && widgetConfig?.cartLink?.href)">
        <a :href="widgetConfig.cartLink.href" class="text-decoration-none">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" width="30px" height="30px" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet" style="enable-background:new 0 0 498.6 498.6;" xml:space="preserve">
            <g transform="translate(0.000000,512) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
              <path d="M2420 5113 c-596 -64 -1089 -519 -1196 -1104 l-16 -88 -337 -3 -338 -3 -40 -27 c-22 -15 -51 -44 -64 -65 l-24 -38 0 -1825 0 -1825 24 -38 c13 -21 42 -50 64 -65 l41 -27 1226 0 1226 0 41 27 c62 41 88 90 88 168 0 78 -26 127
              -88 168 l-41 27 -1093 3 -1093 2 0 1560 0 1560 199 0 200 0 3 -332 c3 -331 3 -333 27 -371 13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 24 38 24 40 27 371 l3 332 959 0 959 0 3 -332 c3 -331 3 -333
              27 -371 13 -21 42 -50 64 -65 34 -23 52 -27 107 -27 55 0 73 4 107 27 22 15 51 44 64 65 24 38 24 40 27 371 l3 332 199 0 200 0 2 -892 3 -893 24 -38 c13
              -21 42 -50 64 -65 34 -22 52 -27 106 -27 79 0 128 25 169 88 l27 41 0 1025 0 1026 -24 38 c-13 21 -42 50 -64 65 l-40 27 -338 3 -337 3 -16 89 c-30 163 -101 341 -194 485 -274 427 -782 672 -1282 618z m371 -422 c182 -47 320 -128
              454 -265 126 -129 210 -280 249 -445 l14 -61 -948 0 -948 0 14 61 c39 165 123 316 249 445 151 154 310 240 518 280 100 19 299 12 398 -15z"/>            
            </g>
          </svg>
          <div class="cart-product-count" :style="{ 'margin-top': '-' + (parseInt(widgetConfig.countMarginTop) - 2) + 'px', 'margin-left': widgetConfig.countMarginTop + 'px'}">{{products.length}}</div>
        </a>
      </div>
      <div class="pointer-cursor" v-if="widgetConfig?.showUserAccount">
        <SfIcon :icon="'account'" size="xl" class="d-flex"/>
      </div>
    </div>
    <SfBottomModal :is-open="showSearchInput" class="search-modal" :class="{ 'display-none': !showSearchInput }" @click:close="closeEvent">
      <span class="search-input-section">
          <div class="display-inline-flex dmform">
            <SfInput
              v-model="searchInput"
              :label="t('SEARCH')"
              name="search"
              class="form__element h-50px dmforminput"
              @keyup.enter.native="searchProduct"
            />
            <SfButton class="sf-button h-50px dmWidget"
              name="applySelectedFilters"
              @click="searchProduct">
              <SfIcon class="text" :icon="'search'"/>
            </SfButton>
          </div>
        </span>
    </SfBottomModal>
  </div>
</template>
<script>

import {
  SfIcon,
  SfInput,
  SfButton,
  SfBottomModal
} from "@storefront-ui/vue";
export default {
  name: "HeaderSection",
  components: {
    SfIcon,
    SfInput,
    SfButton,
    SfBottomModal
  },
  props: ['token', 'widgetConfig'],
  data() {
    return {
      iCartId : localStorage.getItem('cart') && localStorage.getItem('cart') != '' ? localStorage.getItem('cart') : undefined,
      products : [],
      headers: {},
      optionContextPosition: {},
      showSearchInput: false,
      searchInput: '',
      iBusinessId: undefined,
      coreApiUrl : 'https://core.1-retailgear.org',
      cashRegisterApiUrl : 'https://cash.2-retailgear.org'
    };
  },
  mounted() {
    this.headers = {
      headers: { Authorization: this.token }
    };
    this.iBusinessId = this.widgetConfig?.iBusinessId || '6182a52f1949ab0a59ff4e7b';
    this.fetchCartDetails();
    this.fetchCustomerDetails();
  },
  computed: {
  },
  methods: {
    fetchCartDetails(){
      if(this.iCartId){
        this.isLoading = true;
        this.products = [];
        this.axios.get(`${this.cashRegisterApiUrl}/api/v1/carts/${this.iCartId}?iBusinessId=${this.iBusinessId}`, this.headers).then((response) => {
          if(response?.data?.data?.products?.length > 0){
            this.products = response.data.data.products;
          }
        })
      } else {
        this.products = [];
      }
    },
    searchProduct(){
      if(this.widgetConfig?.searchLink?.href)
        window.location.href = `${window.location.origin}${this.widgetConfig.searchLink.href}?key=${this.searchInput}`;
    },
    fetchCustomerDetails(){

    },
    showOptions(){
      // let elementPosition = event.currentTarget.getBoundingClientRect();
      // this.optionContextPosition = {
      //   top: Math.floor(elementPosition.height + 10) + 'px',
      //   // left: Math.floor(elementPosition.left - 40) + 'px',
      //   'margin-top': '0px',
      //   width: 'max-content',
      //   'background-color': 'white' 
      // };
      this.showSearchInput = true;
    },
    closeEvent(){
      this.showSearchInput = false;
    },
  }
  // directives: {
  //   clickoutside: {
  //     bind: function(el, binding, vnode) {
  //       el.clickOutsideEvent = function(event) {
  //         if (!(el == event.target || el.contains(event.target))) {
  //           vnode.context[binding.expression](event);
  //         }
  //       };
  //       document.body.addEventListener("click", el.clickOutsideEvent);
  //       document.body.addEventListener("touchstart", el.clickOutsideEvent);
  //     },
  //     unbind: function(el) {
  //       document.body.removeEventListener("click", el.clickOutsideEvent);
  //       document.body.removeEventListener("touchstart", el.clickOutsideEvent);
  //     },
  //     stopProp(event) {
  //       event.stopPropagation();
  //     }
  //   }
  // },
};
</script>
<style lang="scss" scoped>
  @import "~@storefront-ui/vue/styles";
  .header-section{
    align-self: end;
    ::v-deep svg{
      margin-bottom: auto;
    }
  }
  .d-flex{
    display: flex;
  }
  .display-inline-flex{
    display: inline-flex;
  }
  .hidden{
    display: none;
  }
  .text-decoration-none{
    text-decoration: none;
  }
  .cart-section{
    @include for-mobile{
      margin-top: -3px;
    }
  }
  .cart-product-count{
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color:var(--color_1, inherit);
  }
  .h-50px{
    height: 50px !important;
  }
  ::v-deep .dmWidget .sf-icon{
    fill: var(--btn-text-color);
    padding: 2px 0 !important;
  }
  .search-input-section{
    padding: 5px;
    border-radius: 5px;
    text-align: left;
  }
  .pointer-cursor{
    cursor: pointer;
  }
  .search-modal{
    --bottom-modal-top: 0 !important;
    z-index: 1000 !important;
    .dmform{
      width: 100%;
      &input{
        width: 100%;
      }
    }
    ::v-deep .sf-circle-icon--small {
      --icon-size: 14px;
      --button-size: 40px;
      margin-top: 20px;
    }
  }
</style>