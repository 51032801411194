<template>
  <div class="app header-section">
    <div class="dmRespRow">
      <div class="dmRespCol large-12 medium-12 small-12">
        <HeaderSection :token="publicToken" :widgetConfig="widgetConfig" v-if="publicToken"/>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderSection from './components/header-section.vue';

export default {
  name: 'App',
  props: ['element','widgetConfig'],
  data() {
    return{
      publicToken : undefined,
      coreApiUrl : 'https://core.1-retailgear.org',
    }
  },
  components: {
    HeaderSection
  },
  mounted() {
    console.log("app mounted", this.widgetConfig)
    this.getPublicToken();
  },
  methods: {
    getPublicToken() {
      let tempToken = this.getWithExpiry('authKey');
      if(tempToken){
        this.publicToken = tempToken;
        this.fetchLocalTranslations(this.widgetConfig?.locale || 'nl');
        return;
      }
      let apiUrl = "https://auth.1-retailgear.org/api/v1/public-token"
      this.axios.get(apiUrl).then((response) => {
        this.publicToken = response.data.data // for all requests
        this.fetchLocalTranslations(this.widgetConfig?.locale || 'nl');
        this.setWithExpiry('authKey', this.publicToken);
      })
    },

    setWithExpiry(key, value) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.setDate(now.getDate() + 3),
      }
      localStorage.setItem(key, JSON.stringify(item))
    },

		getWithExpiry(key) {
      const itemStr = localStorage.getItem(key)
      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }
      const item = JSON.parse(itemStr)
      // compare the expiry time of the item with the current time
      if (new Date() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
      }
      return item.value
    },
    fetchLocalTranslations(lang){
      let headers = { headers: { Authorization: this.publicToken } };
      this.axios.get(`${this.coreApiUrl}/api/v1/translation/all/${lang.slice(0,2)}?widget='header-section'`, headers).then((result) => {
        if(result?.data?.data?.length > 0){
          let localTranslation = {};
          localTranslation[lang] = Object.assign({}, ...result.data.data);
          this.$translate.setLocales(localTranslation);
          this.$translate.setLang(lang);
        } 
      })
    }
  }
}
</script>

<style>
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  --product-card-add-button-right: 0.5rem;
  --product-card-add-button-bottom: -1rem;
}
.search-input-section .sf-input__label{
  left: 10px !important;
}
</style>
