import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTranslate from 'vue-translate-plugin';

import "@storefront-ui/vue/styles.scss";

Vue.use(VueAxios, axios) 
Vue.use(VueTranslate)
Vue.config.productionTip = false

// axios.defaults.baseURL = "http://54.246.32.179:3000"
var app;

export function init ({ containerId, container, props }) {
  const parent = container || document.getElementById(containerId);
  axios.defaults.headers.common['organization-id'] = props.widgetConfig?.org || 'neworg9'
  app = new Vue({
    render: h => h(App, { props: props }),
  }).$mount()

  parent.appendChild(app.$el);
}

export function clean () {
  app.$destroy();
}

export function setFiltersData () {
  console.log(app);
}
if (process.env.NODE_ENV === "development") {
  init ({
    containerId: 'app',
    props: {
      dudaConfig: {
        headline: 'Apps are Awesome',
        element: 'h1'
      }
    }
  });
}
